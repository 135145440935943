.about-container {
  display: flex;
  flex-direction: column;
  background-color: #050505;
  min-height: 100vh;
  align-items: center;
  padding-bottom: 50px !important;
  padding: 20px;
}
.about-heading {
  color: #ffbe2e;
  font-size: 60px;
  text-align: center;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  letter-spacing: -4px;
}
.about-sub-container {
  display: flex;
  flex-direction: row;
  width: 95%;
}
.get-to-know-me-container {
  width: 100%;
  margin-right: 200px;
}
.my-skill-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.skill-logo {
  width: 100px;
  height: 100px;
}
.skill-container {
  background-color: #fff3de;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px;
}
.get-to-know-me-heading {
  font-size: 35px;
  color: #fff3de;
}
.get-to-know-me-para {
  font-size: 28px;
  color: #fff3de;
}
@media only screen and (max-width: 920px) {
  .about-container {
    min-height: 50vh;
  }
  .about-heading {
    font-size: 40px;
    letter-spacing: -2px;
  }
  .about-sub-container {
    flex-direction: column;
  }
  .get-to-know-me-container {
    width: 100%;
  }
  .my-skill-container {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .get-to-know-me-heading {
    font-size: 25px;
    color: #fff3de;
    text-align: center;
  }
  .get-to-know-me-para {
    font-size: 18px;
    color: #fff3de;
    text-align: center;
  }
  .skill-logo {
    width: 70px;
    height: 70px;
  }
  .skill-container {
    background-color: #fff3de;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
  }
}

/* cd .. && rm -rf tastyKitchensApp && mkdir tastyKitchensApp && cd tastyKitchensApp && git clone https://github.com/Lichi-Dev/TastyKitchen.git /home/workspace/reactjs/coding-practices/tastyKitchensApp */
/* #050505 */
