.contact-container {
  display: flex;
  flex-direction: column;
  background-image: url("../../Assets/herobg2.jpg");
  padding-bottom: 50px;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.contact-para {
  color: #fff3de;
  font-size: 20px;
  text-align: center;
  font-family: "League Gothic", sans-serif;
  margin-top: -20px;
  padding: 8px;
}
.name-input {
  border: 2px solid #ffbe2e;
  width: 50%;
  height: 30px;
  outline: none;
  margin-bottom: 20px;
  background-color: #fff3de;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
}
.name-input::placeholder {
  color: black !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.message-input {
  border: 2px solid #ffbe2e;
  width: 50%;
  height: 200px;
  resize: none;
  outline: none;
  margin-bottom: 20px;
  background-color: #fff3de;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
}
.message-input::placeholder {
  color: black !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.error-message {
  color: red;
}
.error-container {
  margin-bottom: 20px;
  margin-top: -18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.button-contact {
  height: 45px;
  background-color: transparent;
  border: 2px solid #ffbe2e;
  color: #ffbe2e;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  width: 150px;
}
.button-contact:hover {
  border: 2px solid #ffbe2e;
  background-color: #ffbe2e;
  color: #111111;
}
@media only screen and (max-width: 920px) {
  .name-input {
    border: 2px solid #ffbe2e;
    width: 90%;
    height: 30px;
    outline: none;
    font-size: 16px;
  }
  .message-input {
    border: 2px solid #ffbe2e;
    width: 90%;
    height: 200px;
    resize: none;
    outline: none;
    font-size: 16px;
  }
  .error-container {
    width: 90%;
  }
  .button-contact {
    height: 40px;

    font-size: 14px;
    width: 120px;
  }
}
