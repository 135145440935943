.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  width: 100%;
}
.footer-logo {
  width: 40px;
  height: 40px;
}
.footer-upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin: 20px;
}
.icons {
  text-decoration: none;
  color: white;
  margin-right: 10px;
}
.twitter:hover {
  color: #00acee !important;
}
.linkedin:hover {
  color: #0072b1 !important;
}
.instagram:hover {
  color: #d62976 !important;
}
.github:hover {
  color: grey !important;
}
.whatsapp:hover {
  color: #25d366;
}
.social-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin-bottom: 20px;
}
.logo-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #ffffff;
}
.logo-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partition {
  border-top: 1px solid white;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5px;
  width: 70%;
}
.copyright-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #ffffff;
}
@media only screen and (max-width: 37.5em) {
  .footer-upper-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0px;
    margin-top: 20px;
  }
  .social-text {
    text-align: center;
    margin-bottom: 10px;
  }
  .logo-container {
    align-items: center;
  }
  .partition {
    margin-top: 10px;
  }
}
