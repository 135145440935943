.education-container {
  display: flex;
  flex-direction: column;
  background-image: url("../../Assets/herobg2.jpg");
  align-items: center;
  background-size: cover;
  background-position: center;
  padding-bottom: 90px;
}
.education-heading {
  color: #fff3de;
  font-size: 60px;
  text-align: center;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  letter-spacing: -4px;
}
.education-sub-container {
  background-color: transparent;
  border: 2px solid #fff3de;
  padding: 20px;
  width: 60%;
  margin-top: 30px;
  height: 150px;
  border-radius: 8px;
}
.education-degree {
  color: #ffbe2e;
  font-size: 40px;
  font-weight: 600;
  margin-top: -10px;
}
.education-year {
  color: #fff3de;
  font-size: 20px;
  font-weight: 700;
  margin-top: -10px;
}
.education-college {
  color: #fff3de;
  font-size: 20px;
  font-weight: 400;
}
@media only screen and (max-width: 800px) {
  .education-container {
    min-height: 60vh;
    padding-bottom: 40px;
  }
  .education-heading {
    font-size: 40px;
    letter-spacing: -1px;
  }
  .education-degree {
    font-size: 20px;
  }
  .education-year {
    font-size: 12px;
  }
  .education-college {
    font-size: 12px;
  }
  .education-sub-container {
    height: auto;
    width: 80%;
  }
}
