.project-image {
  width: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.project-container {
  background-color: #050505;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  padding: 20px;
}
.a-link {
  text-decoration: none;
}
.project-sub-heading {
  font-size: 35px;
  color: #fff3de;
}
.project-heading {
  color: #ffbe2e;
  font-size: 60px;
  text-align: center;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  letter-spacing: -4px;
}
.client-work-heading {
  color: #ffbe2e;
  font-size: 30px;
  text-align: center;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
}
.project-description-para {
  font-size: 16px;
  color: #fff3de;
  margin-top: -10px;
}
.project-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.project-description-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.project {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 95%;
}
.button {
  height: 45px;
  background-color: transparent;
  border: 2px solid #ffbe2e;
  color: #ffbe2e;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 4px;
  width: 150px;
}
.button:hover {
  border: 2px solid #ffbe2e;
  background-color: #ffbe2e;
  color: #111111;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
}
@media only screen and (max-width: 930px) {
  .project-image {
    width: 300px;
  }
  .project {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .project-image {
    width: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
    order: 0;
  }
  .project-description-container {
    order: 1;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .project-heading {
    text-align: center;
  }
  .project-description-para {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .project-heading {
    font-size: 40px;
    letter-spacing: -2px;
  }
  .project-image {
    width: 220px;
  }
  .button {
    height: 42px;

    width: 120px;
  }
}

/* #101010 */
