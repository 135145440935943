.navbar-container {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  height: 100px;
  width: 100%;
  z-index: 100;
}
.menu-container {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}
.menu-items {
  color: white;
  font-size: 18px;
  margin-right: 55px;
  font-weight: 600;
}
.menu {
  display: none;
}
.menu-items:hover {
  color: #ffbe2e;
}
.logo {
  width: 40px;
  height: 40px;
  margin-left: 50px;
}
.hamburger-menu {
  display: none;
}
.resume-button {
  height: 40px;
  background-color: transparent;
  border: 2px solid #ffbe2e;
  color: #ffbe2e;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 4px;
}
.resume-button:hover {
  height: 40px;
  border: 2px solid #ffbe2e;
  background-color: #ffbe2e;
  color: #111111;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
}
.resume-button-mobile {
  height: 40px;
  border: none;
  background-color: #ffbe2e;
  color: black;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 100px;
  margin-top: 10px;
}
.hamburger-menu-container {
  display: none;
}
@media only screen and (max-width: 37.5em) {
  .navbar-container {
    height: 70px;
  }
  .logo {
    width: 35px;
    height: 35px;
    margin-left: 30px;
  }
  .hamburger-menu {
    display: block;
    color: white;
    margin-right: 20px;
    font-size: 30px;
  }
  .menu-container {
    display: none;
  }
  .menuMask {
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 50;
  }
  .menu {
    position: fixed;
    background-color: #050505;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    z-index: 50;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-menu-header {
    color: white;
  }
  .mobile-menu-items {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .mobile-menu-container {
    border-bottom: 1px solid #ffbe2e;
    padding: 30px;
  }
  .mobile-menu-items:hover {
    color: #ffbe2e;
  }
  .resume-button {
    display: none;
  }
  .hamburger-menu-container {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .menu-items {
    color: white;
    font-size: 12px;
    margin-right: 25px;
    font-weight: 600;
  }
  .resume-button {
    height: 35px;
    width: 100px;
    font-size: 9px;
  }
}
@media only screen and (max-width: 900px) {
  .resume-button {
    height: 35px;
    width: 100px;
    font-size: 9px;
  }
}
